<template>
  <main class="main" role="main">
      <div class="main-container">

                <div class="timezone-container mw-100">
                    <h1 class="heading">
                        Cities starting with the letter {{tz}}
                    </h1>
                   
                     <div class="auto">
                        <autocomplete @selected="setCity" placeholder="Search for Regions / Cities" ref="autocomplete" v-model="cityAuto" :source= "$store.state.api_url + '_/_search?q='" results-property="city" results-display="city">
                        </autocomplete>
                    </div><br>
                    <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                        <div id="waldo-tag-12625"></div>
                    </div> -->
                    <div class="timezone-flex">
                        <a :href="$router.resolve({name: 'region', params: {region: r.B.toLowerCase(), city: r.C}}).href" v-for="r in regions" v-bind:key="r.D">{{r.D}}</a>
                        <!-- <router-link :to="{path:'/'+r.B.toLowerCase()+'/'+r.C}" v-for="r in regions" v-bind:key="r.D">{{r.D}}</router-link> -->
                        <img v-if="loading" src="@/assets/loading.gif">
                    </div>
                    <!-- <div style="display: flex; flex-grow: 1; justify-content: center; margin-top: 4px">
                        <div id="waldo-tag-12626"></div>
                    </div> -->
                </div>


                
            </div>
            <footers></footers>
    </main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import footers from '@/views/Footer.vue'
import Autocomplete from 'vuejs-auto-complete'
export default {
    components: {footers,Autocomplete},
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            regions:[],
            tz:false,
            loading:false,
             cityAuto: ""
            //api_url:"http://localhost:5000/_read",
        }
    },
    watch: {
        '$route': {
            handler(newValue, oldValue) {
                this.tz = this.$route.params.q.toUpperCase()
                this.init()
                // setTimeout(d=>{
                //  waldo.refreshTag("waldo-tag-12625")
                //  waldo.refreshTag("waldo-tag-12626")
                //     console.log(3)
                // },1000);
            }
        }
    },
    created() {
        this.tz = this.$route.params.q.toUpperCase()
       this.init()
    //    setTimeout(d=>{
    //              waldo.refreshTag("waldo-tag-12625")
    //              waldo.refreshTag("waldo-tag-12626")
    //     },1000)
    },
    methods: {
        setCity() {
            setTimeout(f => {
                this.$router.push({
                    path: "/" + this.cityAuto
                });
            }, 200)

        },
       init(){
           this.loading = true
            this.regions  = []
            document.title =  "Cities starting with the letter "+this.$route.params.q.toUpperCase()+" at clock.zone";
            axios.post(this.$store.state.api_url + "data",{alphabet:this.$route.params.q}).then(response => {
                        this.regions = (response.data.data)
                        this.regions.sort((a, b) => a.D - b.D );
                         this.loading = false
                }).catch(error => {
                 
              })
       }
    }
}
</script>
<style scoped>
.auto {
    width: 97%;
    margin: 0 auto;
    margin-top: 25px;
}
</style>